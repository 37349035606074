<template>
    <div class="login-wrap">
        <div class="i-login-back-head">
           <div class="i-back-head-img"></div>
           <span class="i-back-head-text">登录</span>
           <div class="i-back-head-right-text"></div>
        </div>
        <div class="login-content">
          <div class="ant-row ant-form-item ant-form-item-has-success">
            <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                    <div class="phone-input">
                      <input
                        v-model="phone"
                        class="ant-input i-input"
                        placeholder="手机号"
                        type="text"/>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="ant-row ant-form-item ant-form-item-has-success">
            <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                    <div style="position: relative">
                    <input
                        v-model="smscode"
                        class="ant-input i-input"
                        placeholder="验证码"
                        maxlength="10"
                        type="text"/>
                    <button
                        @click="sendSmsCode"
                        type="button"
                        class="ant-btn ant-btn-link"
                        style="position: absolute;right: 0px;top: 0px;height: 44px;" 
                        :disabled="downCount > 0">
                        <span v-if="downCount > 0">{{downCount}}s 后重发</span>
                        <span v-else>获取验证码</span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="ant-row ant-form-item" style="padding-top: 16px; margin-bottom: 0px">
            <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                    <button
                      @click="loginCode"
                      class="ant-btn i-button button-red"
                      style="width: 100%">
                      <span>登录</span>
                    </button>
                </div>
                </div>
            </div>
          </div>
        <div style="text-align: center; height: 68px">
            <span @click="gotoLogin" class="login-content-to-account">密码登录</span>
        </div>
        <div class="auths-box">
            <div class="flex-center auths" style="margin-bottom: 14px">
               <img @click="getWechatQrcodeUrl" src="../../assets/static/wechat.0137e3cd.svg" />
            </div>
            <div style="font-size: 12px; text-align: center">
               登录即同意<router-link tag="a" target="_blank" :to="{path:'/userAgreement'}">《用户协议》</router-link>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import {setUserInfo} from '@/utils';

export default {
  name: "loginCode",
  data() {
    return {
       phone: '',
       smscode: '',
       downCount: 0,
       smstype: 1
    }
  },
  created() {},
  methods: {
    sendSmsCode(){
      this.$http.post('/api/sendSmsCode',{phone: this.phone,smstype: this.smstype}).then(()=>{
        this.$Message.success("验证码发送成功");
        clearTimeout(this.downCountTimeOut);
        this.downCount = 60;
        this.startDownCount();
      });
    },
    startDownCount(){
      this.downCountTimeOut = setTimeout(() => {
         if(this.downCount > 0){
            this.downCount --;
            this.startDownCount();
         }
      }, 1000);
    },
    loginCode(){
      this.$http.post('/api/loginWithSmsCodeOnlyLogin',{username: this.phone,smscode: this.smscode,smstype: this.smstype}).then(data=>{
        let backName = this.$route.query.backName || 'account';
        setUserInfo(data);
        this.$router.push({name: backName});
      });
    },
    gotoLogin(){
      this.$router.push({name: 'login'});
    },
    getWechatQrcodeUrl(){
       this.$http.get('/api/common/getWechatQrcodeUrl',{state: 'login'}).then(data=>{
          location.href = data;
       });
    }
  }
};
</script>
<style scoped>
.loginCode {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>